import Request from '../Request.utils'
export interface IListRequestQuery {
  page?: number;
  resellerId?: string;
}
export interface IAllRequestQuery {
  resellerId?: string;
}
export interface IUpsertRequestBody {
  data?: any;
  id?: string;
  name?: string;
  type?: string;
  url?: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IArchiveRequestBody {
  id: string;
}
export interface ITestShopifyConnectionRequestBody {
  token?: string;
  url?: string;
}
export interface IPublishProductRequestBody {
  cost?: number;
  designId?: string;
  name?: string;
  price?: number;
  productId?: string;
  storeIds?: string[];
  type?: string;
  updateExisting?: boolean;
  variants?: {
    galleries?: string[];
    image?: string;
    price?: number;
    style?: string;
    variantDesignId?: string;
  }[];
}
export interface IFindPublishedProductsRequestParams {
  published_product_id: string;
}
export interface IUnpublishProductRequestBody {
  designId: string;
  productId: number;
  storeId: string;
}
export interface ITestPIWRequestBody {
  color?: string;
  font?: string;
  orientation?: string;
  productColor?: string;
  shopifyProductId?: number;
  text?: string;
}
export interface IOrderPackingSlipsRequestParams {
  orderId: string;
}
export interface IDispatchToRoyalMailRequestBody {
  billing?: {
    address?: {
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      countryCode?: string;
      county?: string;
      fullName?: string;
      postcode?: string;
    };
    emailAddress?: string;
    phoneNumber?: string;
  };
  orderDate?: string;
  orderReference?: string;
  packages?: {
    contents?: {
      SKU?: string;
      name?: string;
      quantity?: number;
      unitValue?: number;
      unitWeightInGrams?: number;
    }[];
    packageFormatIdentifier?: string;
    weightInGrams?: number;
  }[];
  recipient?: {
    address?: {
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      countryCode?: string;
      county?: string;
      fullName?: string;
      postcode?: string;
    };
    emailAddress?: string;
    phoneNumber?: string;
  };
  shippingCostCharged?: number;
  subtotal?: number;
  total?: number;
}
export interface IDispatchToRoyalMailCreateTrackingNumberRequestBody {
  orderIdentifier?: number;
}
export interface IGetOrderInfoFromShopifyIdRequestParams {
  orderId: string;
}
export interface IGetRoyalMailOrderStatusRequestBody {
  orderIdentifiers?: number[];
}
export interface IGetRoyalMailOrdersRequestBody {
  orderId?: any;
}
export interface ICreateNotExistShopifyEmbedAppRequestBody {
  name?: string;
  url?: string;
}
export interface IAddPublishedProductToDesignRequestBody {
  designId?: string;
  product?: any;
  storeUrl?: string;
}
export interface IShopifyAppClaimMyStoreRequestBody {
  storeId: string;
}
export interface IGetShopifyStoreInfoRequestParams {
  cms_store_id: string;
}
export interface IUpdateShopifyOrderStatusRequestBody {
  orders?: {
    lineItems?: any[];
    orderId?: number;
    storeId?: string;
    trackingNumber?: string;
  }[];
}
export interface IUpdateInProductionOrdersRequestBody {
  orders?: any[];
}
export interface IEtsyGetTokenRequestBody {
  code?: string;
  code_verifier?: string;
  redirect_uri?: string;
  store_name?: string;
}


class ShopAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/online-stores', 'GET', undefined, query, undefined, );
    return res;
  }
  all = async (query: IAllRequestQuery) => {
    const res = await Request.call('/api/online-stores/all', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/online-stores', 'POST', undefined, undefined, body, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/online-stores/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  archive = async (body: IArchiveRequestBody) => {
    const res = await Request.call('/api/online-stores/archive', 'POST', undefined, undefined, body, );
    return res;
  }
  testShopifyConnection = async (body: ITestShopifyConnectionRequestBody) => {
    const res = await Request.call('/api/online-stores/test-shopify-connection', 'POST', undefined, undefined, body, );
    return res;
  }
  publishProduct = async (body: IPublishProductRequestBody) => {
    const res = await Request.call('/api/online-stores/publish-product', 'POST', undefined, undefined, body, );
    return res;
  }
  findPublishedProducts = async (params: IFindPublishedProductsRequestParams) => {
    const res = await Request.call('/api/online-stores/find-published-products/:published_product_id', 'GET', params, undefined, undefined, );
    return res;
  }
  unpublishProduct = async (body: IUnpublishProductRequestBody) => {
    const res = await Request.call('/api/online-stores/unpublish-product', 'POST', undefined, undefined, body, );
    return res;
  }
  shopifyWebhook = async () => {
    const res = await Request.call('/api/online-stores/:id/shopify-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  resellerHealthCheck = async () => {
    const res = await Request.call('/api/online-stores/reseller-health-check', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopifyWebhookPartnerInWine = async () => {
    const res = await Request.call('/api/online-stores/partner-in-wine/shopify-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  testPIW = async (body: ITestPIWRequestBody) => {
    const res = await Request.call('/api/online-stores/partner-in-wine/test-output', 'POST', undefined, undefined, body, );
    return res;
  }
  shopifyWebhookGreatHarbourGifts = async () => {
    const res = await Request.call('/api/online-stores/great-harbour-gifts/shopify-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  orderPackingSlips = async (params: IOrderPackingSlipsRequestParams) => {
    const res = await Request.call('/api/online-stores/order-packing-slips/:orderId', 'GET', params, undefined, undefined, );
    return res;
  }
  dispatchToRoyalMail = async (body: IDispatchToRoyalMailRequestBody) => {
    const res = await Request.call('/api/online-stores/dispatch-to-royal-mail', 'POST', undefined, undefined, body, );
    return res;
  }
  dispatchToRoyalMailCreateTrackingNumber = async (body: IDispatchToRoyalMailCreateTrackingNumberRequestBody) => {
    const res = await Request.call('/api/online-stores/dispatch-to-royal-mail/create-tracking-number', 'POST', undefined, undefined, body, );
    return res;
  }
  getOrderInfoFromShopifyId = async (params: IGetOrderInfoFromShopifyIdRequestParams) => {
    const res = await Request.call('/api/online-stores/order-info-from-shopify/:orderId', 'GET', params, undefined, undefined, );
    return res;
  }
  getRoyalMailOrderStatus = async (body: IGetRoyalMailOrderStatusRequestBody) => {
    const res = await Request.call('/api/online-stores/get-royal-mail-order-status', 'POST', undefined, undefined, body, );
    return res;
  }
  getRoyalMailOrders = async (body: IGetRoyalMailOrdersRequestBody) => {
    const res = await Request.call('/api/online-stores/get-royal-mail-orders', 'POST', undefined, undefined, body, );
    return res;
  }
  createNotExistShopifyEmbedApp = async (body: ICreateNotExistShopifyEmbedAppRequestBody) => {
    const res = await Request.call('/api/online-stores/upsert-shopify-embed-app', 'POST', undefined, undefined, body, );
    return res;
  }
  addPublishedProductToDesign = async (body: IAddPublishedProductToDesignRequestBody) => {
    const res = await Request.call('/api/online-stores/add-published-product-to-design', 'POST', undefined, undefined, body, );
    return res;
  }
  shopifyAppWebhook = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-webhook', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopifyAppWebhookPost = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  shopifyAppConnectCallback = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-connect-callback', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopifyAppConnect = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-connect', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopifyAppClaimMyStore = async (body: IShopifyAppClaimMyStoreRequestBody) => {
    const res = await Request.call('/api/online-stores/shopify-app-claim-my-store', 'POST', undefined, undefined, body, );
    return res;
  }
  getShopifyStoreInfo = async (params: IGetShopifyStoreInfoRequestParams) => {
    const res = await Request.call('/api/online-stores/shopify-store-info/:cms_store_id', 'GET', params, undefined, undefined, );
    return res;
  }
  getAdminShopifyDiscountCodes = async () => {
    const res = await Request.call('/api/online-stores/admin-shopify-discount-code', 'GET', undefined, undefined, undefined, );
    return res;
  }
  uninstallStoreWebhook = async () => {
    const res = await Request.call('/api/online-stores/:store_id/uninstall', 'POST', undefined, undefined, undefined, );
    return res;
  }
  updateShopifyOrderStatus = async (body: IUpdateShopifyOrderStatusRequestBody) => {
    const res = await Request.call('/api/online-stores/update-shopify-order', 'POST', undefined, undefined, body, );
    return res;
  }
  updateInProductionOrders = async (body: IUpdateInProductionOrdersRequestBody) => {
    const res = await Request.call('/api/online-stores/update-in-production-orders', 'POST', undefined, undefined, body, );
    return res;
  }
  etsyGetToken = async (body: IEtsyGetTokenRequestBody) => {
    const res = await Request.call('/api/online-stores/etsy-get-token', 'POST', undefined, undefined, body, );
    return res;
  }
  etsyRequestConnect = async () => {
    const res = await Request.call('/api/online-stores/etsy-request-connect', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopify_webhook_customer_data = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-mandatory-webhooks/customer_data', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopify_webhook_data_erasure = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-mandatory-webhooks/data_erasure', 'GET', undefined, undefined, undefined, );
    return res;
  }
  shopify_webhook_shop_data_erasure = async () => {
    const res = await Request.call('/api/online-stores/shopify-app-mandatory-webhooks/shop_data_erasure', 'GET', undefined, undefined, undefined, );
    return res;
  }
}
export default new ShopAPI()
import axios from "axios";
import { urltoFile } from "helpers/EditorSDK";
import apiClient, { TProduct } from "store/api-client"

export const BLEND_URL = {
  hat: "https://print-manager-media.s3.eu-west-1.amazonaws.com/files/bg/2d-render/blend-files/1728549617856/Cap_4-cm231que1000a357015vgce0g.blend",
}
export const BLEND_SUPPORT_URL = "https://print-manager-media.s3.eu-west-1.amazonaws.com/files/bg/2d-render/blend-files/1721543308374/Studio-Light-Set.exr"

export const getPreviewPTSAction = async (params: {
  artworkUrl?: string,
  artworkUrls?: string[],
  productType: string,
  product?: TProduct,
}) => {
  const { artworkUrl, productType, artworkUrls, product } = params;

  const _params: any = {
    artworkUrl,
    artworkUrls,
    productType: productType || "common",
  }
  if (product?.data?.ptsActions) {
    _params.actionScript = product?.data?.ptsActions;
  }
  if (product?.data?.psdUrl) {
    _params.psdUrl = product?.data?.psdUrl;
  }

  const res = await apiClient.Api.Pdf.ptsActionArtwork(_params)
  return res;
}

const renderUsingRunpod = async ({ artworkUrl, blend, support, angle }: any) => {
  const randomString = Math.random().toString(36).substring(7);
  const apiUrlRunpod = `https://dev.services.personify.tech/api/bg/blender-render`;
  const res = await fetch(apiUrlRunpod, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      // artworkBase64: await getArtworkBase64(),
      artworkUrl,
      blend,
      support,
      angle,
      output: `msc/2d-render/${new Date().getTime()}_${randomString}.png`,
    }),
  });
  const json = await res.json();
  return json.data.url;
}

export const renderAngle = async (artworkUrl: string, angle = "0") => {
  const blendData = {
    blender: BLEND_URL.hat,
  }

  const apiUrlGPUServer = `https://bg-production.personify.tech/api/images/blender-2d-render`;
  try {
    const url = await renderUsingRunpod({
      artworkUrl,
      blend: blendData.blender,
      support: BLEND_SUPPORT_URL,
      angle,
    });
    return url;
  } catch (err) {
    console.log('FALLBACK RENDER', err);
    const res = await fetch(apiUrlGPUServer, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        // artworkBase64: await getArtworkBase64(),
        artworkUrl,
        blend: blendData.blender,
        support: BLEND_SUPPORT_URL,
        angle,
      }),
    });
    const json = await res.json();
    console.log('json', json);
    return json.data.url;
  }
}

export const uploadFileToS3 = async (artworkUrl: any) => {
  const id = new Date().getTime() + '_' + Math.random().toString(36).substring(7);
  const image = await urltoFile(artworkUrl, `${id}.png`, "image/png");
  const uploadRes =
    await apiClient.Api.User.getUploadPresignedUrl({
      contentType: "image/png",
      serverSidePath: `msc/${id}.png`,
    });
  if (!uploadRes?.data.success) throw Error(uploadRes?.data.error);
  const s3uploadUrl = uploadRes?.data.data;
  const upload = await fetch(s3uploadUrl, {
    method: "PUT",
    headers: { "Content-Type": "image/png" },
    body: image,
  });
  if (!upload.ok) throw Error(upload.statusText);
  return s3uploadUrl.split("?")?.[0];
}

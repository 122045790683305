import React, { useEffect, useMemo, useState } from 'react';
import { IScreen, TDesign } from 'type';
import { CMSLayout, Col, Row, Text, useUIState, useRefState, Table, TouchField, Select, Grid, RatioCol, } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { TimeHelper, modalConfirm } from 'helpers';
import Store from 'store';
import { Image, StyleSheet } from 'react-native';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { FontAwesome5, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { Tooltip } from 'react-tippy';
import { Dropdown, Space } from 'antd';

const ListDesigns: IScreen = () => {
  const { navigation, route } = useNavFunc();
  const UserStore = Store.useUserStore();
  const DesignStore = Store.useDesignStore();
  const [filter, setFilter] = useState();
  const [isGrid, setGridView] = useState(window.innerWidth <= 768 ? true : false);
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [designs, getDesigns, setDesigns] = useRefState<Array<TDesign>>([])

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      if (getDesigns().length === 0) {
        setUI({ fetching: true, errorMes: '' });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await DesignStore.getList(p, '', resellerId);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setDesigns(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    getData(1);
    return unsubscribe;
  }, []);

  const onChangeFilter = (data) => {
    setFilter(data?.value);
  }

  const statusFilterOpts = useMemo(() => [
    { label: "Published", value: "published" },
    { label: "Not Published", value: "not_published" },
  ], [])

  const filteredDesign = useMemo(() => {
    if (!filter) return designs;
    if (filter === "published") return designs.filter(i => i.products?.length > 0);
    return designs.filter(i => !i.products?.length);
  }, [designs, filter])

  const confirmDelete = (record) => {
    modalConfirm({
      title: 'Delete',
      content: `${record.products.length > 0 ? 'Deleting this product will also unpublish products from stores. ' : ''}Once deleted this product cannot be recovered. Do you wish to continue?`,
      onOk: async () => {
        const res = await DesignStore.deleteDesign(record.id);
        if (res) {
          setDesigns(s => s.filter(i => i.id !== record.id));
          for (let i = 0; i < record.products.length; i++) {
            const p = record.products[i];
            await Store.Api.Shop.unpublishProduct({
              designId: record.id,
              productId: +p.productId,
              storeId: p.storeId,
            });
          }
        }
      },
    })
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Product Image',
      key: 'productImage',
      render: (record) => (
        <Image source={{ uri: record.image }} style={styles.productImg} resizeMode='contain' />
      )
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Store',
      key: 'store',
      render: (record) => (
        <Text>{record?.brands?.map(v => v.name).join(', ')}</Text>
      )
    },
    {
      title: 'Resale Price',
      dataIndex: 'resalePrice',
      key: 'resalePrice',
      render: (value) => `£${value || 0}`,
    },
    {
      title: 'Date Created',
      key: 'created',
      render: (record) => (
        <Text>{moment(record.createdAt).format('DD/MM/YYYY')}</Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (
        <Text>{record.products.length > 0 ? 'Published' : 'Not published'}</Text>
      ),
    },
    {
      title: 'Stock',
      key: 'status',
      render: (record) => (
        <Text>{record.product?.data?.isOutStock ? 'Out of Stock' : 'In Stock'}</Text>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 160,
      render: (record) => {
        const products = record?.products;
        let iconColor = COLOR.FONT;
        let imgQualityStatus = '';
        if (record.otherData?.imageQuality) {
          if (/Sexual|Nudity/.test(JSON.stringify(record.otherData?.imageQuality?.labels))) {
            iconColor = 'red';
            imgQualityStatus = "Artwork contains explicit content - please review";
          } else if (record.otherData?.imageQuality?.sharpness < 0.8) {
            iconColor = 'orange';
            imgQualityStatus = "The quality of artwork is low - please review";
          } else if (record.otherData?.imageQuality?.sharpness) {
            iconColor = COLOR.GREEN;
            imgQualityStatus = "The quality of artwork is good";
          }
        }

        return (
          <Row justifyContent="flex-end">
            <Tooltip
              title={"Visit this product on the connected store/s"}
              trigger="mouseenter"
              position="top"
            >
              {products?.length <= 1 ? (
                <TouchField
                  opacity={products?.length > 0 ? 1 : 0.5}
                  width={40} height={40} middle mr1
                  disabled={!products?.length}
                  onPress={() => {
                    if (products?.length === 1) window.open(products?.[0]?.url);
                  }}
                >
                  <MaterialCommunityIcons name="store" size={26} color={COLOR.FONT} />
                </TouchField>
              ) : (
                <Dropdown
                  menu={{
                    items: products.map(v => ({
                      key: v.url,
                      label: (
                        <a target="_blank" href={v.url} onClick={e => e.stopPropagation()}>
                          {v.brand}
                        </a>
                      ),
                      disable: false,
                    }))
                  }}
                >
                  <a onClick={(e) => {
                    e.preventDefault();
                  }}>
                    <Space style={{ marginRight: 10, width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <MaterialCommunityIcons name="store" size={26} color={COLOR.FONT} />
                    </Space>
                  </a>
                </Dropdown>
              )}
            </Tooltip>
            <Tooltip
              title={imgQualityStatus}
              trigger="mouseenter"
              position="top"
            >
              <TouchField
                onPress={() => {
                  navigation.navigate(SCREEN.ResellerCreateMyOwnProduct, {
                    designId: record.id,
                    productId: record.productId,
                  });
                }}
                width={40} height={40} middle mr1
              >
                <MaterialCommunityIcons name="eye" size={26} color={iconColor} />
              </TouchField>
            </Tooltip>
            <Tooltip
              title={"Delete this product. Your product will be unpublished when deleted"}
              trigger="mouseenter"
              position="top"
            >
              <TouchField width={40} height={40} middle onPress={() => confirmDelete(record)}>
                <MaterialCommunityIcons name="trash-can" size={26} color={COLOR.FONT} />
              </TouchField>
            </Tooltip>
          </Row>
        )
      },
    },
  ];

  const mainContent = () => {
    if (isGrid) {
      return (
        <Col p2>
          <Grid xs='50%' md='25%' marginHorizontal={-25}>
            {filteredDesign.map((val, i) => {
              const showPublishedStatus = UserStore.user?.role === 'reseller';
              const isPublished = val.products.length > 0;
              return (
                <Col paddingTop={40} bgWhite shadow overflow={'hidden'} round0 m0 p0 key={val.id}
                  onPress={() => navigation.navigate(SCREEN.ResellerCreateMyOwnProduct, { designId: val.id, productId: val.productId })}
                >
                  <RatioCol
                    width={'100%'}
                    ratio={1}
                  >
                    <Image source={{ uri: val.image }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                  </RatioCol>
                  <Row mb0 height={25}>
                    {val.brands.length > 0 && (
                      <Text caption numberOfLines={2}>
                        Brands:
                        <Text bold ml0 p0>
                          {val.brands.map(v => v.name).join(', ')}
                        </Text>
                      </Text>
                    )}
                  </Row>
                  <Col height={1} backgroundColor={'rgba(0,0,0,0.05)'} />
                  <Row height={30} mt0>
                    <Text numberOfLines={2} bold>{val.name}</Text>
                  </Row>
                  <Text caption>Resale Price: <Text bold fontSize={20}>£{val.resalePrice || 0}</Text></Text>
                  <Col
                    absolute top={0} right={0} width={115}
                  >
                    {showPublishedStatus && (
                      <Col borderBottomLeftRadius={10}
                        backgroundColor={isPublished ? '#2E8304' : '#FF9A01'}
                        p0 middle
                      >
                        <Text color='white' caption>{isPublished ? 'Published' : 'Not published'}</Text>
                      </Col>
                    )}
                    {/* <Col
                      p0 backgroundColor={COLOR.MAIN} marginTop={2} middle
                    >
                      <Text color='white' caption>{val.isCustomizable ? 'Customizable' : 'Print on Demand'}</Text>
                    </Col> */}
                  </Col>
                </Col>
              )
            })}
          </Grid>
        </Col>
      )
    }
    return (
      <Table
        data={filteredDesign}
        columns={columns}
        rowKey={record => `${record.id}`}
        minWidth={800}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigation.navigate(SCREEN.ResellerCreateMyOwnProduct, {
                designId: record.id,
                productId: record.productId,
              });
            },
          }
        }}
        loading={fetching}
      />
    )
  };

  return (
    <CMSLayout requireAuthen
      breadcrumbs={[
        { title: `My Products` },
      ]}
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          {/* <Text h3 >My Products</Text> */}
          <Select
            noBorder borderThin
            width={200} round0 bgWhite
            placeholder='Filter'
            options={statusFilterOpts}
            value={statusFilterOpts.find(v => v.value === filter)}
            onChange={onChangeFilter}
            innerProps={{ isClearable: true }}
          />
        </Row>
        <Row>
          <TouchField padding={8} onPress={() => setGridView(s => !s)}>
            <Ionicons name={isGrid ? "list" : "grid"} size={22} color={COLOR.MAIN} />
          </TouchField>
        </Row>
      </Row>
      <Col flex1 m2>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : mainContent()}
      </Col>
    </CMSLayout>
  );
};

const styles = StyleSheet.create({
  productImg: {
    width: 100,
    height: 100,
    borderWidth: 1,
    borderColor: COLOR.GREY,
  }
})

ListDesigns.routeInfo = {
  title: 'My Designs - MSupply',
  path: '/my-designs',
};

export default ListDesigns;

import { Col, IColProps, Row, Text } from "components/base";
import React, { useRef, useState } from "react";
import BgIcon from "../BgIcon";
import { Upload } from "antd";
import { ActivityIndicator } from "react-native";
import Store from "store";
import axios from "axios";
import UploadFile from "./UploadFile";
import Input from "./Input";
import Button from "../button/Button";

interface Props extends IColProps {
  title?: string,
  icon?: string,
  value?: string,
  onChange?: (txt: string) => void,
  isInput?: boolean,
}

const UploadField = (props: Props) => {
  const { title, icon, value, onChange, isInput, ...restProps } = props;
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef<{ showDialog: Function }>(null);

  const onUpload = async (opts) => {
    const { file, onProgress, onError, onSuccess } = opts;
    if (loading) return;
    const uploadName = `${new Date().getTime() + '-'}${file.name}`;
    const serverSidePath = `files/${uploadName}`;
    try {
      setLoading(true);
      const res1 = await Store.Api.User.getUploadPresignedUrl({
        serverSidePath,
        contentType: file.type || 'application/octet-stream',
      });
      const awsUrl = res1.data.data
      if (!awsUrl) return undefined;

      const res2 = await axios.put(awsUrl, file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
        },
        onUploadProgress: (progressEvent) => {
          onProgress({ percent: Math.round((progressEvent.loaded / progressEvent.total) * 100) })
        },
      });
      if (res2.status !== 200) return undefined;
      const u = new URL(awsUrl);
      onSuccess(res2.data);
      onChange?.(`${u.origin}${u.pathname}`);
    } catch (err) {
      onError(err);
      return undefined;
    } finally {
      setLoading(false);
    }
  }

  const onImageUploaded = urls => {
    if (urls.length === 0) return setLoading(false);
    onChange?.(urls?.[0])
    setLoading(false)
  }

  if (isInput) {
    return (
      <Col {...restProps}>
        {Boolean(title || icon) && (
          <Row marginBottom={8} alignItems="center">
            <Text fontWeight="500" fontSize={14} marginRight={8}>{title}</Text>
            {!!icon && (
              <BgIcon name={icon} size={14} />
            )}
          </Row>
        )}
        <Input
          placeholder=""
          value={value}
          mb1
          flexDirection={'row'}
          alignItems={'center'}
          onChange={onChange}
          buttonRight={
            <Button
              isLoading={loading}
              mr1
              text="Upload"
              height={30}
              borderRadius={15}
              width={100}
              onPress={() => {
                setLoading(true);
                uploadRef.current.showDialog();
              }}
            />
          }
        />
        <UploadFile
          ref={uploadRef}
          onUploaded={onImageUploaded}
        />
      </Col>
    )
  }

  const uploadButton = (
    <Col alignItems="center">
      {loading ? <ActivityIndicator /> : <BgIcon name="plus" size={16} />}
      <div style={{ marginTop: 4 }}>Upload</div>
    </Col>
  );

  return (
    <Col {...restProps}>
      {Boolean(title || icon) && (
        <Row marginBottom={8} alignItems="center">
          <Text fontWeight="500" fontSize={14} marginRight={8}>{title}</Text>
          {!!icon && (
            <BgIcon name={icon} size={14} />
          )}
        </Row>
      )}
      <Upload
        name="logo"
        listType="picture-card"
        showUploadList={false}
        customRequest={onUpload}
        method="PUT"
      >
        {value ? <img src={value} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </Col>
  )
}

export default UploadField;
